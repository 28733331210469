import React from 'react';
import Title from "../../atoms/Title/Title"
import Button from "../../atoms/Button/Button"
import { graphql } from "gatsby";
import SectionBackground from "../../molecules/SectionBackground/SectionBackground";
import {css} from '@emotion/react';
import { Element } from "react-scroll"
import Image from '../../atoms/Image/Image';
import Paragraph from '../../atoms/Paragraph/Paragraph';
import Icon from '../../atoms/Icon/Icon';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';

export default function EligeProyecto({
  titulo,
  tituloProyecto,
  subtitulo,
  depas,
  colorFondo,
  colorFondoTexto,
  colorFondoBotonDepaActivo,
  colorTextoBotonDepaActivo,
  colorFondoBotonDepaNoActivo,
  colorTextoBotonDepaNoActivo,
  colorFondoBarraTitulo,
  colorTextoBarraTitulo,
  colorBarraUbicacion,
  colorFondoBotonesUbicacion,
  colorTextoBarraUbicacion,
  colorIconoBarraUbicacion,
  colorIconoBotonesUbicacion,
  colorPrimario,
  colorTitulo
  }) {

  const sectionCss = css`
    padding-top: calc(100vw * (calc(60 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(60 / var(--width_base))));
  `;


  const carouselCss = css`
    color: black;
    text-align: left;
    display: none;
    &.is-active{
      display: block;  
    }
  `;

  const titleCss = css`
    margin-bottom: calc(100vw * (calc(76 / var(--width_base))));
  `;

  const contentCarousel = css`
    display: flex;
    flex-wrap: wrap;
  `;

  const carouselLeft = css`
    position: relative;
    flex-shrink: 0;
    width: calc(100vw * (calc(497 / var(--width_base))));
    background-color: white;
    
    @media (max-width: 767px){
        width: 100%;
    }
  `;

  const carouselRight = css`
    flex-grow: 1;
    background-color: ${colorFondoTexto};
  `;

  const carouselUbicacion = css`
    width: 100%;
    display: flex;
    background-color: ${colorBarraUbicacion};
    padding: calc(100vw * (calc(19 / var(--width_base))));
    align-items: center;
    color: ${colorTextoBarraUbicacion};
    
    @media (max-width: 767px){
      flex-direction: column;
      justify-content: center;
      padding-top: calc(100vw * (calc(42 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(60 / var(--width_base))));
    }
  `;

  const ubicacionCss = css`
    display: flex;
    margin-right: calc(100vw * (calc(60 / var(--width_base))));
    @media (max-width: 767px){
        margin-right: initial;
        margin-bottom: calc(100vw * (calc(38 / var(--width_base))));
    }
  `;

  const ubicacionTextCss = css`
  font-size: calc(100vw * (calc(20 / var(--width_base))));
  font-style: italic;
  `;

  const ubicacionIconCss = css`
    margin-right: calc(100vw * (calc(10 / var(--width_base))));
    margin-top: calc(100vw * (calc(3 / var(--width_base))));
    font-size: calc(100vw * (calc(25 / var(--width_base))));
    color: ${colorIconoBarraUbicacion};
  `;

  const carouselSubtitleCss = css`
  background-color: ${colorFondoBarraTitulo};
  color: ${colorTextoBarraTitulo};
  font-size: calc(100vw * (calc(22 / var(--width_base))));
  padding: calc(100vw * (calc(50 / var(--width_base))));
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  `;

  const carouselDescriptionCss = css`
    padding-top: calc(100vw * (calc(40 / var(--width_base))));
    padding-left: calc(100vw * (calc(40 / var(--width_base))));
    padding-right: calc(100vw * (calc(40 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    background-color: ${colorFondoTexto};
  `;

  const carouselButtons = css`
  display: block;
  margin-bottom: calc(100vw * (calc(30 / var(--width_base))));
  `;

  const carouselListCss = css`
    column-count: 2;
    @media (max-width: 767px){
        column-count: initial;
    }
  `;

  const carouselListItem = css`
  display: flex;
  break-inside: avoid;
  height: calc(100vw * (calc(60 / var(--width_base))));
  align-items: center;
  & .carousel-list__text{
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    text-align: left;
  }
  & .carousel-list__imagen.gatsby-image-wrapper-constrain img{
    width: calc(100vw * (calc(25 / var(--width_base))));
    height: calc(100vw * (calc(25 / var(--width_base))));
    object-fit: contain;
  }
  & .carousel-list__imagen{
    width: calc(100vw * (calc(25 / var(--width_base))));
    flex-shrink: 0;
    margin-right: calc(100vw * (calc(20 / var(--width_base))));
  }
  `;

  const carouselButton = css`
    padding: calc(100vw * (calc(10 / var(--width_base)))) calc(100vw * (calc(14 / var(--width_base))));
    background-color: ${colorFondoBotonDepaNoActivo};
    border-radius: calc(100vw * (calc(10 / var(--width_base))));
    font-size: calc(100vw * (calc(22 / var(--width_base))));
    font-style: italic;
    &:not(:last-of-type){
      margin-right: calc(100vw * (calc(20 / var(--width_base))));
    }
    color: ${colorTextoBotonDepaNoActivo};
    &.is-active{
      background-color: ${colorFondoBotonDepaActivo};
      color: ${colorTextoBotonDepaActivo};
    }
  `;

  const iconButtonSliderCss = css`
    font-size: calc(100vw * (calc(50 / var(--width_base)))) !important;
    display: none;
    @media (max-width: 767px){
        display: block;
    }
  `;

  const buttonSliderCss = css`
    position: absolute;
    top: calc(100vw * (calc(170 / var(--width_base))));
    z-index: 1;
    &:first-of-type{
      left: 0;
    }
    &:last-of-type{
      right: 0;
    }
  `;

  const handleClick = (event, indice) => {
    let depas = document.querySelectorAll(".sliderdepa");
    depas && depas.forEach((depa) =>{
      depa.classList.remove("is-active");
    });
    let depaActivo = document.getElementById(`sliderdepa-${indice}`);
    depaActivo && depaActivo.classList.add("is-active");
  }

  const handleClickSliderRightMovil = (e, i, totalDepas) =>{
    if(totalDepas){
      let depaActivo = null;
      let indice = i + 1;
      let depas = document.querySelectorAll(".sliderdepa");
      depas && depas.forEach((depa) =>{
        depa.classList.remove("is-active");
      });
      if(indice === totalDepas) depaActivo = document.getElementById(`sliderdepa-0`);
      else depaActivo = document.getElementById(`sliderdepa-${indice}`);
      depaActivo && depaActivo.classList.add("is-active");
    }
  }
  const handleClickSliderLeftMovil = (e, i, totalDepas) =>{
    if(totalDepas){
      let depaActivo = null;
      let indice = i + 1;
      let depas = document.querySelectorAll(".sliderdepa");
      depas && depas.forEach((depa) =>{
        depa.classList.remove("is-active");
      });
      if(indice === 1) depaActivo = document.getElementById(`sliderdepa-${totalDepas-1}`);
      else depaActivo = document.getElementById(`sliderdepa-${i - 1}`);
      depaActivo && depaActivo.classList.add("is-active");

    }
  }


  return (
    <SectionBackground align="center" widthContent="1200" styleCss={sectionCss} bgColor={colorFondo}>
      <Element name="ficha"/>
      <Title text={titulo} typeEle="h2" color={colorTitulo} type="draft" colorBorde={colorPrimario} styleCss={titleCss}/>
        {depas && depas.map((depa, i)=>(
          <div key={i} css={carouselCss} className={`sliderdepa ${(i === 0) ? "is-active": ""}`} id={`sliderdepa-${i}`}>
            <div css={contentCarousel}>
              <div css={carouselLeft}>
                {
                  depa.imagen &&
                  <InnerImageZoom src={depa.imagen.sourceUrl} zoomSrc={depa.imagen.sourceUrl} zoomScale={1.5} zoomType="hover" hideCloseButton={true}/>
                }
                <button css={buttonSliderCss} onClick={(event) => handleClickSliderLeftMovil(event, i, depas.length)}><Icon aria-label="button" type="arrow-left" color="black" styleEle={iconButtonSliderCss}/></button>
                <button css={buttonSliderCss} onClick={(event) => handleClickSliderRightMovil(event, i, depas.length)}><Icon aria-label="button" type="arrow-right" color="black" styleEle={iconButtonSliderCss}/></button>
              </div>
              <div css={carouselRight}>
                <Paragraph text={subtitulo} styleCss={carouselSubtitleCss}/>
                <div css={carouselDescriptionCss}>
                  <div css={carouselButtons}>
                    {
                      depas && depas.map((item, j)=>(
                      <button className={(i === j ? "is-active": "")} key={j} css={carouselButton} onClick={(event) => handleClick(event, j)}>{item.nombre}</button>
                      ))
                    }
                  </div>
                  <div css={carouselListCss}>
                    {depa.descripcion && depa.descripcion.map((item,i)=>(
                      <div key={i} css={carouselListItem}>
                        <Image imagen={item.icono} classEle="carousel-list__imagen" objectFit="contain"/>
                        <Paragraph text={item.texto} classEle="carousel-list__text"/>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div css={carouselUbicacion}>
                <div css={ubicacionCss}>
                  <i className="fas fa-map-marker-alt" css={ubicacionIconCss}/>
                  <Paragraph text={depa.ubicacion} styleCss={ubicacionTextCss}/>
                </div>
                { depa?.pdfBrochure &&
                  <Button link={depa?.pdfBrochure?.mediaItemUrl} type="pdf" nombre={`Brochure ${tituloProyecto}`} styleType="normal" color={colorFondoBotonesUbicacion}>
                    <Icon type="brochure" color={colorIconoBotonesUbicacion} />
                    Descargar Brochure
                  </Button>
                }
                { depa?.whatsapp &&
                  <Button link={depa?.whatsapp} type="whatsapp" styleType="normal" color={colorFondoBotonesUbicacion}>
                    <Icon type="whatsapp" color={colorIconoBotonesUbicacion}/>
                    Whatsapp
                  </Button>
                }
                { depa?.recorrido360?.uri &&
                  <Button link={depa?.recorrido360?.uri} type="link" styleType="normal" color={colorFondoBotonesUbicacion}>
                    <Icon type="recorrido" color={colorIconoBotonesUbicacion}/>
                    Recorrido 360
                  </Button>
                }
              </div>
            </div>
          </div>
        ))}
    </SectionBackground>
  );

}

export const queryEligeProyecto = graphql`
  fragment eligeProyecto on Wordpress_Template_Proyecto_Proyecto{
    tituloEligeElQue
    subtituloEligeElQue
    colorFondoBarraTituloEligeElQue
    colorFondoBarraUbicacionEligeElQue
    colorFondoBotonDepaActivoEligeElQue
    colorFondoBotonDepaNoActivoEligeElQue
    colorFondoBotonesBarraUbicacionEligeElQue
    colorFondoEligeElQue
    colorFondoTextoEligeElQue
    colorIconoBarraUbicacionEligeElQue
    colorIconoBotonesBarraUbicacionEligeElQue
    colorTexotBotonDepaActivoEligeElQue
    colorTextoBarraTituloEligeElQue
    colorTextoBarraUbicacionEligeElQue
    colorTextoBotonDepaNoActivoEligeElQue
    departamentosEligeElQue {
      descripcion {
        icono {...imagen}
        texto
      }
      imagen {...imagen}
      nombre
      pdfBrochure {mediaItemUrl}
      recorrido360 {
        ... on Wordpress_Page {
          uri
        }
      }
      ubicacion
      whatsapp
    }
  }
`


EligeProyecto.defaultProps = {
  colorFondo: "transparent",
  colorFondoTexto: "transparent",
}
